import { PageLayout } from "@components";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import { JoinInstitutionsV2 } from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import { Demo, GetWorkDone } from ".././biopharmaceutical";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import { FeatureROI } from "@assets/icons";
import OnboardWorkflowV4 from "@components/CentralizedPlatform/OnboardWorkflow";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function StartupsPage() {
	const demoProps = {
		title: "Operating system that scales your startup",
		subtitle:
			"Genemod provides startups with low-cost, easy-to-use lab automation software needed to scale life sciences R&D. Contact us today to see if your startup qualifies.",
		srcImg: "blue-leather-boxing.png",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(
			styles.getADemoOverride,
			styles.clinicalGetADemoOverride
		),
		titleStylesOverride: styles.CRODemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride,
	};

	const cards: FeatureCardProps[] = [
		{
			title: "All the features you need for inventory management",
			list: [
				"We’ve built your favorite Excel-like features to make data entry painless",
				"Create custom item types or choose from premade templates",
				"Generate automations for 100+ use cases",
			],
			sublist: [
				"Keep track of item types, concentrations, attachments, and notes of any samples and reagents stored in your lab.",
				"Default item templates include primer, strain, antibody, chemical, enzyme, cell line, and more.",
				"Create automations that alert you when items are running low or when updates have been made.",
			],
			sublistColor: "text-helper",
			image: (
				<StaticImage
					src={
						"../../../assets/images/landing_page/products_inventory.png"
					}
					alt="Easily Manage and Submit Orders"
				/>
			),
			className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
	];

	const featureCardTwo: FeatureCardProps = {
		title: "Genemod Notebook takes R&D collaboration to a whole other leve",
		list: [
			"Collaborative real-time editing",
			"Create, comment, and reply to protocols",
			"Centralize attachments and metadata",
		],
		sublist: [
			"We take teamwork to the next level by letting you and your team work together in real time on experiments.",
			"Make it a team effort with in-line and page comments and replies.",
			"Easily upload and manage relevant attachments within experiments, as well as create new sections.",
		],
		sublistColor: "text-helper",
		image: (
			<StaticImage
				src={"../../../assets/solutions/live-editing-file.png"}
				alt="Collaborative real-time editing"
			/>
		),
		className: styles.startupFeatureQuickAccess,
		listItemWeight: "SEMIBOLD",
		titleStylesOverride: styles.featureTitleStylesOverride,
	};
	const featureSummaryProps = {
		title: "We partner with accelerators, incubators, and VCs",
		subtitle:
			"Ask your organization if they offer Genemod for Startups. Set your company up for success from day one.",
		list: [
			"Introducing Genemod Activate",
			"Where research teams get work done",
		],
		sublist: [
			"Genemod Activate is a startup program for portfolio companies that have raised up to Series A rounds of funding.",
			"We’ve built the first platform for life sciences R&D where project management meets electronic lab notebook.",
		],
		img: <FeatureROI />,
		imageLeft: true,
		reverse: true,
		applyV4Styles: true,
		titleStylesOverride: styles.startupDemoFeatureSummaryTitle,
		sectionWrapper: styles.featureSummarySectionWrapper,
		contentOverride: styles.featureSummaryContentOverride,
		subtitleStyles: styles.startupDemoSubtitleStylesOverride,
		listStyles: styles.startupListStyles,
	};
	const getWorkDoneProps = {
		title: "Position your startup for success from day one",
		subtitle: "We bring your team’s work together in one shared space. Improve process management by leaving the repetitive stuff to us so you can do more of the work you do best.",
		image: <OnboardWorkflowV4 style={{ width: "100%" }} />,
		showSquares: false,
		showCentralizedPlatformV2: true,
		showSubtitleBottom: false,
		titleWidth: 920,
		subtitleWidth:1040,
		applyV4Styles: true,
		sectionWrapper: styles.startupGetWorkSectionWrapper,
	};
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "research" }}>
			<Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
			<FeatureSummary {...featureSummaryProps} />
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					<FeatureCard {...featureCardTwo} />
				</FeatureCardLayout>
			</Margins>
			<Margins className={styles.getWorkDoneMargins}>
				<GetWorkDone {...getWorkDoneProps} />
			</Margins>
			<LogosAndG2Section/>
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>
		</PageLayout>
	);
}
